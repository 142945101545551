@charset "utf-8";

html,
body,
#root {
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
  overflow-x: auto;
  font-family: 'Spoqa Han Sans Neo', '맑은 고딕', 'malgun gothic', 'AppleGothicNeoSD',
    'Apple SD 산돌고딕 Neo', 'Microsoft NeoGothic', 'Droid sans', sans-serif;
  font-weight: 400;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
menu,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
main,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 13px;
  font-family: 'Spoqa Han Sans Neo', '맑은 고딕', 'malgun gothic', 'AppleGothicNeoSD',
    'Apple SD 산돌고딕 Neo', 'Microsoft NeoGothic', 'Droid sans', sans-serif;
  box-sizing: border-box;
  font-weight: 400;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section {
  display: block;
}
/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
  display: none;
}
body {
  margin: 0 !important;
  line-height: 1;
}
menu,
ol,
ul,
li {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
}

strong,
b {
  font-weight: 700;
}
a {
  vertical-align: middle;
  text-decoration: none;
  color: inherit;
}

input,
select,
textarea {
  vertical-align: middle;
}
em,
address {
  font-style: normal;
}
button {
  border: 0;
  cursor: pointer;
}

form {
  margin: 0;
  padding: 0;
}
fieldset {
  border: 0;
}
caption,
legend,
.blind {
  overflow: hidden;
  width: 0;
  height: 0;
  line-height: 0;
  font-size: 0;
  text-indent: -9999px;
}

@font-face {
  font-family: 'Spoqa Han Sans Neo';
  font-weight: 700;
  src: local('Spoqa Han Sans Neo Bold'),
    url('./ui/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Bold.woff2') format('woff2'),
    url('./ui/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Bold.woff') format('woff'),
    url('./ui/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Spoqa Han Sans Neo';
  font-weight: 500;
  src: local('Spoqa Han Sans Neo Medium'),
    url('./ui/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Medium.woff2') format('woff2'),
    url('./ui/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Medium.woff') format('woff'),
    url('./ui/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Spoqa Han Sans Neo';
  font-weight: 400;
  src: local('Spoqa Han Sans Neo Regular'),
    url('./ui/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Regular.woff2') format('woff2'),
    url('./ui/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Regular.woff') format('woff'),
    url('./ui/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Spoqa Han Sans Neo';
  font-weight: 300;
  src: local('Spoqa Han Sans Neo Light'),
    url('./ui/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Light.woff2') format('woff2'),
    url('./ui/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Light.woff') format('woff'),
    url('./ui/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Spoqa Han Sans Neo';
  font-weight: 100;
  src: local('Spoqa Han Sans Neo Thin'),
    url('./ui/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Thin.woff2') format('woff2'),
    url('./ui/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Thin.woff') format('woff'),
    url('./ui/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Thin.ttf') format('truetype');
}

/* DatePicker */
.MuiPaper-root {
  .MuiDateCalendar-root {
    width: 290px;
    height: auto;
    max-height: 380px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    & > div {
      &:has(.MuiYearCalendar-root) {
        padding: 20px 6px 20px 20px;
      }
      &:has(.MuiDayCalendar-root) {
        padding: 0 20px 20px 20px;
      }
      width: 100%;
      padding: 20px;
    }
  }
  .MuiPickersCalendarHeader-root {
    margin: 8px 0;
    padding: 0;

    .MuiPickersArrowSwitcher-root {
      width: 66px;
      justify-content: space-between;

      .MuiPickersArrowSwitcher-spacer {
        width: 2px;
      }
      button svg {
        display: none;
      }
      > button {
        width: 32px;
        height: 32px;
      }
      > button:first-child {
        background: url('components/asset/BreadCrumbArrow.svg') center center no-repeat;
        background-size: 16px 16px;
        transform: scaleX(-1);
      }
      > button:last-child {
        background: url('components/asset/BreadCrumbArrow.svg') center center no-repeat;
        background-size: 16px 16px;
      }
    }
  }
  .MuiPickersCalendarHeader-labelContainer {
    width: 144px;
    margin-right: 42px;
  }
  .MuiPaper-root-MuiPickersPopper-paper {
    width: 250px;
  }
  .MuiDayCalendar-weekDayLabel {
    margin: 0;
  }
  .MuiPickersDay-root {
    margin: 0;
  }
  .MuiYearCalendar-root {
    width: 100%;
    max-height: 240px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 4px 12px 0 0;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 4px; /* 스크롤바의 너비 */
      background: #f7f9f8; /*스크롤바 뒷 배경 색상*/
    }
    &::-webkit-scrollbar-thumb {
      height: 3%; /* 스크롤바의 길이 */
      background: #979998; /* 스크롤바의 색상 */
      border-radius: 30px;
    }
    &::-webkit-scrollbar-track {
      background: transparent; /*스크롤바 뒷 배경 색상*/
    }
  }
  .MuiPickersYear-yearButton {
    margin: 0;
    border-radius: 2px;
    color: #1f1f1f;
    background: #fff;
    padding: 6px 8px;
    width: 82px;
    height: 32px;
    font-weight: 300;

    &:hover {
      background-color: #f1f4f3;
      color: #1f1f1f;
    }
  }
  .MuiPickersYear-yearButton.Mui-selected {
    background-color: #2d9bb2 !important;
    color: #fff;
    font-size: 13px;
    font-family: Spoqa Han Sans Neo;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: 0px;
    border-radius: 2px;

    &:hover {
      background-color: #2d9bb2 !important;
    }
  }

  .MuiMonthCalendar-root {
    padding: 0px;
    width: 252px;
  }
  .MuiPickersMonth-root.MuiPickersMonth-root {
    width: 63px;
    height: 32px;
    border-radius: 2px;
    &:not(:nth-last-child(-n + 4)) {
      margin-bottom: 8px;
    }
    &:hover {
      background-color: #f1f4f3;
      color: #1f1f1f;
    }
  }
  .MuiPickersMonth-root {
    -webkit-flex-basis: 0;
    flex-basis: 0;
  }
  .MuiPickersMonth-monthButton.MuiPickersMonth-monthButton {
    font-family: Spoqa Han Sans Neo;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    color: #1f1f1f;

    &:hover {
      background-color: #f1f4f3;
      color: #1f1f1f;
    }
  }
  .MuiPickersMonth-monthButton.Mui-selected.MuiPickersMonth-monthButton {
    background-color: #2d9bb2;
    color: #fff;
    font-size: 13px;
    font-family: Spoqa Han Sans Neo;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: 0px;
    border-radius: 2px;
    width: 63px;
    height: 32px;
    margin: 0;
  }

  .MuiPickersCalendarHeader-label {
    color: #1f1f1f;
    font-weight: 700;
    font-size: 15px;
    padding: 6px;
    margin-right: 0;
  }
  .MuiDayCalendar-header {
    > span {
      width: 36px;
      height: 36px;
      color: #979998;
      font-size: 12px;
      font-weight: 300;
    }
  }
  .MuiDayCalendar-weekContainer {
    button {
      font-size: 12px;
      font-weight: 300;
    }
    button:first-child {
      color: #2d9bb2;
    }
  }
}

.MuiPickersSlideTransition-root.MuiDayCalendar-slideTransition {
  width: auto;
  height: auto;
  min-height: auto;
}

.amsDatePicker {
  background: #ffffff;
}

/* DatePicker 선택된 날짜 설정 */
.MuiButtonBase-root.MuiPickersDay-root.Mui-selected {
  background-color: #2d9bb2 !important;
}

/* /DatePicker 오늘날짜 설정 */
.MuiButtonBase-root.MuiPickersDay-today.MuiPickersDay-root:not(.Mui-selected) {
  background-color: #3c3e3d !important;
  color: #ffffff;
}
.MuiButtonBase-root.MuiPickersDay-root.Mui-selected {
  background-color: #2d9bb2 !important;
}

/* 날짜 hover */
.MuiButtonBase-root.MuiPickersDay-root:hover {
  background-color: #d9fcf4 !important;
}
.MuiButtonBase-root.MuiPickersDay-root:active {
  background-color: #2d9bb2 !important;
  color: #ffffff;
}

/* DateRangePicker */
.MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected {
  background-color: #2d9bb2 !important;
}
.MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day:not(.Mui-selected) {
  background-color: #3c3e3d !important;
  color: #ffffff;
}

/* GSelectMUIAtom */
.MuiList-root.MuiMenu-list {
  padding: 8px 0 !important;
  border: 1px solid #b9bcbb;
  margin-top: 2px;
  border-radius: 2px;
}

.MuiPaper-root.MuiPopover-paper {
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.11), 0px 0px 2px 0px rgba(0, 0, 0, 0.17);
}

.MuiAutocomplete-option {
  &:hover {
    background-color: #eeeaf8 !important;
  }
}

/* GSearchSelect */
.MuiAutocomplete-option[aria-selected='true'] {
  background: #fff url('components/asset/images/selectConfirm.svg') 98% center no-repeat !important;
  /* 체크인 경우 우측 체크 아이콘 없앰 */
  &:has(.MuiCheckbox-root) {
    background: #fff url('components/asset/images/selectConfirm.svg') 110% center no-repeat !important;
  }
  &:hover {
    background-color: #eeeaf8 !important;
  }
}

/* TimePicker */
.MuiAutocomplete-listbox {
  padding: 0 !important;
  border: 1px solid #b9bcbb;
  margin-top: 2px !important;
  border-radius: 2px;
  background-color: #ffff;
  overflow-y: hidden;
}

.MuiAutocomplete-listbox::-webkit-scrollbar {
  width: 0;
}

.MuiInputBase-root .MuiOutlinedInput-root .Mui-focused .MuiOutlinedInput-notchedOutline,
.MuiInputBase-root .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  outline: none !important;
  border: 1px solid #5b5c5b !important;
}

.MuiAutocomplete-popper {
  ul {
    background-color: #fff;
  }
  li {
    background-color: #fff;
    &:first-child {
      margin-top: 8px;
    }
    &:last-child {
      margin-bottom: 8px;
    }
  }
}

/* tree view mui */
.MuiTreeView-root {
  /* selected status */
  ul {
    margin-left: 0 !important;

    /* 2depth */
    li > .MuiTreeItem-content > div:first-child {
      margin-left: 17px;
    }

    /* 3depth */
    ul:not(:first-child) > div .MuiTreeItem-iconContainer {
      margin-left: 34px;
    }
  }
}

.MuiTreeItem-content {
  font-family: 'Spoqa Han Sans neo';
  font-weight: 400;
  font-size: 13px;
  color: #1f1f1f;
  height: 28px;
  border-left: 4px solid transparent;
  padding: 0 6px;

  /* hover, selected, focused status */
  &.Mui-selected,
  &.Mui-selected.Mui-focused,
  &.Mui-selected:hover,
  &:hover {
    background-color: #eeeaf8 !important;
  }

  /* focused, hover일 때 왼쪽 보더 */
  &.Mui-selected.Mui-focused,
  &.Mui-selected.Mui-selected {
    border-left: 4px solid #a897df;
  }

  &.MuiTreeItem-iconContainer {
    margin-right: 0 !important;
    width: 16px;
    height: 16px;
    margin: 1px;
  }

  > div:last-child {
    padding-left: 2px;
  }
}

/* AutoComplete */
.MuiAutocomplete-popper {
  .MuiAutocomplete-paper {
    width: 100%;
  }
}

#content-layout {
  &:has(.summary) {
    html::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar {
      width: 15px;
    }
    &::-webkit-scrollbar-track {
      background: #fff;
    }
    &::-webkit-scrollbar-thumb {
      background: #979998;
      background-clip: padding-box;
      border: 4px solid transparent;
      border-radius: 999px;
    }
    &::-webkit-scrollbar-button {
      display: none;
    }
  }
}
